.image-preview {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.image-preview p{
    position: relative;
    overflow: hidden;
    width: 100%;
    color: rgba(255, 255, 255, 1.0) !important; 
}

.image-preview::before {
    content: '';
    display: block;
    padding-top: 100%;
}

.image-preview img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-preview p {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: rgba(48, 48, 48, 0.7);
    text-align: center;
}