.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    padding: 6px;
    height: 40px;
    width: 40px;
    cursor: pointer;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* display: none;  */
    opacity: 0%;
    transform: translateY(200%);
    transition: transform 0.6s ease-in-out;
  }

.scroll-to-top-button.visible {
  display: block;
  transform: translateY(0);
  opacity: 100%;
  transition: transform 1.2s;
}