:root {
  --rr-ff-body: "Candara", sans-serif;
  --user-bg: #0a0d15;
  --user-bg-dark: 33, 37, 41;
  --user-header: #18181c;
  --user-text-light: rgb(228, 228, 228);
  --user-scrollbar-track-bg: rgba(200,200,200,1);
  --user-scrollbar-track-bg-hover: rgb(152, 151, 221);
  --user-scrollbar-track-scrollbar-thumb: rgba(90,90,90, 1.0);
  --user-scrollbar-track-scrollbar-thumb-hover: rgba(90,90,90, 1.0);
  --user-dropdown-menu-hover: #454545;
  --user-dropdown-menu-item: #0a0d15;
  --user-header-border-color: #454545;

}

body {
  background-color: var(--user-bg);
  font-family: var(--rr-ff-body);
  font-size: 16px;
  line-height: 1.625;
  font-weight: normal;
  color: var(--rr-color-text-body);
  min-height: 75rem;
  padding-top: 4.5rem;
}

.body__bg_color{
  background-color: var(--user-bg) !important; 
}

.bg-dark {
  --bs-bg-opacity: 1;
  /* background-color: (#17b117, var(--bs-bg-opacity)) !important; */
  background-color: var(--user-header) !important; 
}

.text-light {
  --bs-text-opacity: 1;
  color: var(--user-text-light) !important;
}

/* scrollbar */

.scrollarea {
  max-height: 800px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--user-scrollbar-track-bg); 
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px  var(--user-scrollbar-track-bg-hover);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#fff;
  -webkit-box-shadow: inset 0 0 6px var(--user-scrollbar-track-scrollbar-thumb);
}

::-webkit-scrollbar-thumb:hover {
  background-color:#a5a5a5;
  -webkit-box-shadow: inset 0 0 6px  var(--user-scrollbar-track-scrollbar-thumb-hover);
}

/* dropdown-menu btn header bg-items*/
.dropdown-menu a:hover{
  background-color: var(--user-dropdown-menu-hover);
}

.test{
  background-color:  #f60808;
}

.text-gold{
  background-color: rgb(209, 188, 25) !important;
  color: white !important;
}


/* profile menu */
.profile__item{
  --bs-bg-opacity: 1;
  background-color:  #0a0d15;
  color: white !important;
  border: 1px solid #252525;
  padding: 10px;
}

.profile__item p{
  color: rgb(255, 255, 255);
}

.profile__item svg{
  color: rgb(255, 255, 255);
}

.profile__item:hover{
  --bs-bg-opacity: 1;
  background-color: var(--user-dropdown-menu-hover);
  color: rgb(255, 255, 255); 
}

.profile__item:active{
  --bs-bg-opacity: 1;
  background-color:  #a4a4a4 !important;
  color: rgb(255, 255, 255); 
  
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #868686;
}

img, a{
  -moz-user-select: none; /* Firefox */
  -webkit-user-select: none; /* Safari и Chrome */
  -ms-user-select: none; /* Internet Explorer */
  user-select: none;
}

/* project */
.project__header {
  background-color:  var(--user-header);
  border: 1px solid var(--user-header-border-color) !important;;
}

  /* left_menu */
.left__menu_bg{
  --bs-bg-opacity: 1;
  background-color: var(--user-bg)
} 

.left__menu_edit{
  --bs-bg-opacity: 1;
  background-color: #18181c;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
} 

.left__menu__drop_info{
  --bs-bg-opacity: 1;
  background-color: #18181c !important;
  color: #bebec2;
} 

.left__menu__drop_info p{
  --bs-bg-opacity: 1;
  color: #bebec2 !important;
} 

.left__menu_item{
  --bs-bg-opacity: 1;
  background-color:  var(--user-dropdown-menu-item);
  color: white !important;
  border: 1px solid #252525;
  padding: 10px;
} 

.left__menu_item:hover {
  --bs-bg-opacity: 1;
  background-color:  var(--user-dropdown-menu-hover);
  color: rgb(80, 191, 255); 
} 

.project__card_body{
  --bs-bg-opacity: 1;
  background-color:  #18181c;
} 

/* Search form */
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: #1c1c1e;
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.btn-close{
  background-color: #585858;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #18181c;
  --bs-btn-border-color: #0a58ca;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #101014;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #18181c;
  --bs-btn-border-color: #ff707e;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #101014;
  --bs-btn-hover-border-color: #ff707e;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #18181c;
  --bs-btn-border-color: #63cb9b;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #18181c;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #18181c;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.form-control {
  background-color: #101014 !important;
  background-image: none;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #000000;
  background-clip: padding-box;
  border: var(--bs-border-width) solid #8c8c8c;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
  color: #a2b0bb;
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control:hover {
  color: #a2b0bb;
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::placeholder {
  color: #b8b8b8;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #f4f4f4;
  background-color: #434343;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color:  #434343;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color:  #434343;
}

.form-check-input:checked {
  background-color: #000000 !important;
  border-color: #686af9;
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #b8b8b8;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #101014 !important;
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; 
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
  min-height: 600px;
  width: 100%;
  height: 100vh;
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat;
}

.bg-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.home__text {
    background-color: rgba(43, 43, 43, 0.5);
    max-width: 90%;
    padding: 1%;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;
    overflow: auto;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: .25rem;
  background-color: rgba(24, 24, 27, 0.9);
  border: var(--bs-border-width) solid rgba(13, 110, 253, 0.25);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: rgb(167, 167, 167) !important;
}

.image-header {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.image-header p, .image-header h1 {
  position: absolute;
  bottom: 70%;
  width: 100%;
  background-color: rgba(166, 165, 172, 0.8);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  background-color: rgba(24, 24, 27, 0.9);
  padding: 2%;
  z-index: 1;
  box-sizing: border-box;
  overflow: auto;
}

/* admin */
.icon-admin {
  font-size: 16px;
}
