.white-logo {
    filter: brightness(0) invert(1);
  }

.nav-link {
    position: relative;
  }

.nav-link::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(to top, rgb(255, 255, 255), rgb(247, 249, 255));
    margin-top: 4px;
    transition: opacity 0.9s ease;
    opacity: 0;
    border-radius: 5px;
  }
  
  .nav-link:hover::after {
    opacity: 1;
  }

  .nav-link:hover svg {
    color: white;
  }


