
  input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 2px solid rgba(255, 255, 255, 0.32);
    background: #e9e9e9;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  input[type="submit"],
  .button {
    position: relative;
    background: #0d1758;
    color: white;
    text-transform: uppercase;
    border: none;
    font-weight: 600;
    margin-top: 20px;
    padding: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
    font-weight: 400;
    letter-spacing: 0.5rem;
    transition: 0.3s all;
  }
  
  


